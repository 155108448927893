<template>
    <b-btn style="padding: 12px 16px; gap: 10px;" :variant="variant" @click="clicked" :disabled="downloading" class="d-flex align-items-center text-white">
        <svg v-if="variant !== 'primary'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.44 8.9C20.04 9.21 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24 7.46998 8.91M12 2V14.88" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.3499 12.6504L11.9999 16.0004L8.6499 12.6504" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <img v-else src="@/assets/images/download.svg" alt="download">
        {{ downloading ? 'Downloading...' : (text || $t("common.download")) }}
    </b-btn>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: "primary"
        },
        text: String,
        downloading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        clicked() {
            this.$emit('download-button-clicked')
        }
    }
}
</script>

<style scoped>
button {
    height: 44px;
}
</style>