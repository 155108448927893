<template>
    <button class="bg-white btn btn-sm border" style="border-radius: 4px; border-color: #ced4d9 !important;">
        <img src="@/assets/images/icon-filter.svg" class="mb-1 mr-1" alt="filter"> {{ $t('common.filters') }} <img src="@/assets/images/tick.png" v-if="isActive" alt height="13" />
    </button>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    }
}
</script>

<style>

</style>