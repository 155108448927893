<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from 'vue-simple-spinner';
import * as cloneDeep from "lodash.clonedeep";
import * as moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import FilterBtn from '../../../components/filter-btn.vue';
import {
  FETCH_REMITTANCE_SUMMARY,
  EXPORT_REMITTANCE_SUMMARY_INTO_EXCEL
} from "@/state/actions.type";
import Multiselect from 'vue-multiselect';
import DownloadBtn from '../../../components/download-btn.vue';


export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    Multiselect,
    DownloadBtn,
    DateRangePicker,
    FilterBtn
  },
  data() {
    return {
      // TODO: Fetch channel and method data by APIs
      pickerDates: {
        startDate:"",
        endDate:""
      },
      date_type:"",
      type:"",
      paymentsData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      isFilterApplied: false,
      showFilterSection:false,
      filters: {
        channel:[],
        source_currency:[],
        status:[],
      },
      statuses:[],
      filterOn: [],
      sortBy: "date",
      sortDesc: true,
      fields: [
        { key: "status", sortable: true, label: "Status" },
        { key: "date", sortable: true, label: "Date" },
        { key: "total_txn_amount", sortable: true, label: "Total Txn Amount" },
        { key: "txn_fee", sortable: true, label: "Transaction fee" },
        { key: "with_hodling_tax", sortable: true, label: "With Holding Tax" },
        { key: "refund_amount_sc", sortable: true, label: "Refund Amount" },
        { key: "reserve_credit_bc", sortable: true, label: "Reserve Credit" },
        { key: "reserve_debit_bc", sortable: true, label: "Reserve Debit" },
        { key: "transfer_fee", sortable: true, label: "Transfer Fee"},
        { key: "applied_forex_rate", sortable: true, label: "Applied Forex Rate"},
        { key: "net_amount_transferred", sortable: true, label: "Net Amount Transferred"},
       
      ]
    };
  },
  created() {
    var queryParams = new URLSearchParams(window.location.search)
    var raw_date =Number(queryParams.get("date"));
    this.date_type = queryParams.get("date_type");
    this.type = queryParams.get("type");
    var type_value = queryParams.get("type_value");
    if(this.date_type == "month"){
      var date = new Date(raw_date);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.pickerDates.startDate = date
      this.pickerDates.endDate = lastDay
    }else if(this.date_type == "week"){
      date = new Date(raw_date);
      lastDay = new Date(raw_date);
      lastDay.setDate(lastDay.getDate() + 7);
      this.pickerDates.startDate = date
      this.pickerDates.endDate = lastDay
    }else{
      this.pickerDates.startDate = new Date();
      this.pickerDates.endDate = new Date();
      this.pickerDates.startDate.setDate(this.pickerDates.startDate.getDate() - 30);
    }
    // this.fromDate = "2006-06-10 15:04:05"
    // this.toDate = "2022-10-10 15:04:05"
    if(this.type == "currency"){
        this.filters.source_currency = [type_value]
    }else if(this.type == "channel"){
        this.filters.channel = [type_value]
    }
    this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
    this.paymentsData = cloneDeep(this.paymentsDataFromStore);
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    paymentsDataFromStore() {
      return this.$store.state.remittanceSummary.content;
    },

    isFetchingTransactions() {
      return this.$store.state.remittanceSummary.isFetchingData;
    },

    rows() {
      return this.$store.state.remittanceSummary.total_elements;
    },

    items() {
      return [{
          text: 'Remittance',
          active: true
        },
        {
          text: 'Summary',
          active: true
        }
      ];
    },

    title() {
      return 'Remittance Summary';
    }
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  },

  watch: {
    perPage: function(val) {
      this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: this.currentPage, pagesize: val, filters: this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
    },
    currentPage: function(val) {
      this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: val, pagesize: this.perPage, filters: this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
    },
    paymentsDataFromStore() {
      this.paymentsData = cloneDeep(this.paymentsDataFromStore);
    },
    statuses(){
      this.filters.status = this.statuses
      this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
    },
    // filters: {
    //   handler(){
    //       this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
    //   }, 
    //   deep: true
    // },
    pickerDates: {
        handler(){
          this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
        }, 
        deep: true
    },
  },
  beforeMount() {
  },
  mounted() {
    // Set the initial number of items
    this.paymentsData = cloneDeep(this.paymentsDataFromStore);
    this.totalRows = this.paymentsData.length;
  },

  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    sortByDate(a,b, key) {
      if(key == 'date') {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1-d2;
      }
    },

    resetNotificationError() {
      this.$store.dispatch('notification/clear');
    },
    
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },

    saveFilter(){
      this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
      this.showFilterSection = false;
      if(this.filters.channel.length > 0 || this.filters.source_currency.length > 0) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.filters.channel = [];
      this.filters.source_currency = []
      this.$store.dispatch(`remittanceSummary/${FETCH_REMITTANCE_SUMMARY}`, {pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate})
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },

    exportTransactionIntoExcel() {
      this.$store.dispatch(`remittanceSummary/${EXPORT_REMITTANCE_SUMMARY_INTO_EXCEL}`, { pageIndex: 1, pagesize: 10, filters:this.filters, toDate:this.pickerDates.endDate, fromDate:this.pickerDates.startDate });
      this.showmodal = false;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <date-range-picker v-if="!this.date_type" class="datepicker-custom" style="margin-right: 15px;" opens="right" :autoApply="true" :showDropdowns="true" :ranges="false" v-model="pickerDates">
          <template
            v-slot:input=""
            style="min-width: 350px;"
          >{{ pickerDates.startDate | date }} - {{ pickerDates.endDate | date }}</template>
        </date-range-picker>
         <div v-if="this.date_type" class="datepicker-custom-static mb-1">
            {{this.pickerDates.startDate | date}} - {{this.pickerDates.endDate | date}}
        </div>
        <div v-if="type == 'channel'" class="datepicker-custom-static mb-1 ml-3">
            {{filters.channel[0]}}
        </div>
        <div v-if="type == 'currency'" class="datepicker-custom-static mb-1 ml-3">
            {{filters.source_currency[0]}}
        </div>
        <div class="">
          <div class="pt-0">
                <!-- <template v-slot:title>
                  {{ $t('views.payments.transactions.all-payments') }}
                </template> -->
                <div class="row my-4 d-flex justify-content-between">
                  <div class="col-sm-12 col-xl-4 d-flex align-items-baseline">
                    <div id="tickets-table_length" class="dataTables_length ml-3">
                      <label class="d-flex align-items-baseline">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;Entries
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-xl-8 d-flex justify-content-end">
                    <div class="d-flex w-50">
                      <multiselect
                        v-model="statuses"
                        placeholder="Search Status"
                        :searchable="true"
                        :options="['CREATED', 'REJECTED', 'PENDING','REFUNDED', 'CANCELLED', 'COMPLETED','UNPROCESSED']"
                        :multiple="true"
                        class="sm-multiselect"
                      ></multiselect>
                    </div>
                    <div class="download_button" @click="exportTransactionIntoExcel()">
                      <DownloadBtn />
                    </div>
                    <div v-if="!this.date_type" style="width: max-content; height:35px;" class="filter_button" @click="openFilter()" ><FilterBtn :isActive="isFilterApplied" /></div>
                    <div v-if="showFilterSection" class="filter-section">
                      <div class="bottom-section float-right">
                          <div @click="openFilter()" class="mb-2"><FilterBtn :isActive="isFilterApplied" /></div>
                      </div>
                      <div class="my-3 h-50" style="margin-left: 4%; border-bottom: 1px solid #F0F0F0; padding-bottom: 20px;">
                          <button @click="saveFilter()" class="btn btn-primary btn-sm">
                            Apply
                          </button>
                          <button @click="clearFilter()" style="padding-left: 20px;" class="mr-2 btn btn-sm">Clear Filter</button>
                      </div>
                      <div class="top-section">
                          <div class="filter_channel">
                            <div class="filter_heading" style="padding-left: 20px;">COUNTRY</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_vietnam" value="VND" v-model="filters.source_currency">
                                <label for="filter_vietnam">Vietnam (VND)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_thailand" value="THB" v-model="filters.source_currency">
                                <label for="filter_thailand">Thailand (THB)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_indonesia" value="IDR" v-model="filters.source_currency">
                                <label for="filter_indonesia">Indonesia (IDR)</label>
                              </div>
                          </div>
                          <div class="filter_channels"> 
                              <div class="filter_heading" style="padding-left: 20px;">PAYMENT CHANNELS</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_ESPAY" value="ESPAY" v-model="filters.channel">
                                <label for="filter_ESPAY">ESPAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_BAOKIM" value="BAOKIM" v-model="filters.channel">
                                <label for="filter_BAOKIM">BAOKIM</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_9PAY" value="9PAY" v-model="filters.channel">
                                <label for="filter_9PAY">9PAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_MOMOPAY" value="MOMOPAY" v-model="filters.channel">
                                <label for="filter_MOMOPAY">MOMOPAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_GBPRIMEPAY" value="GBPRIMEPAY" v-model="filters.channel">
                                <label for="filter_GBPRIMEPAY">GBPRIMEPAY</label>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <!-- Custom Search End-->
                </div>
                <div class="table-responsive text-center table-custom">
                  <b-table
                    class="table-centered"
                    :items="paymentsData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :bordered="true"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    sticky-header="500px"
                    show-empty
                    no-local-sorting
                    :empty-text="$t('views.payments.transactions.no-records')"
                    busy.sync="true"
                    hover
                  >
                    <template v-slot:head()="data">
                      <span v-b-tooltip.hover.top :title='data.field.tooltip'>
                        {{ data.label }}
                      </span>
                    </template>
                    <!-- <template v-slot:cell(total_txn_amount)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template>
                    <template v-slot:cell(txn_fee)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template>
                    <template v-slot:cell(total_settlement_amount)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template>
                    <template v-slot:cell(remitted_amount)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template>
                    <template v-slot:cell(transfer_fee)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template>
                    <template v-slot:cell(net_remitted_amount)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template> -->
                    <template v-slot:cell(applied_forex_rate)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div>
                    </template>
                     <!-- <template v-slot:cell(net_amount_transferred)="row">
                      <div>
                        {{row.value.toFixed(2)}}
                      </div> -->
                    <!-- </template> -->

                    <template v-slot:cell(status)="row">
                      <div
                        class="badge font-size-12"
                        :class="{'badge-soft-danger': `${row.value}` === 'REJECTED' || `${row.value}` === 'REFUNDED' || `${row.value}` === 'CANCELLED',
                            'badge-soft-success': `${row.value}` === 'COMPLETED',
                            'badge-soft-warning': `${row.value}` === 'PENDING' || `${row.value}` === 'CREATED' || `${row.value}` === 'UNPROCESSED' }"
                      >{{ row.value}}</div>
                    </template>
                  </b-table>
                  <Spinner v-if="isFetchingTransactions === true"></Spinner>
                  <!-- <div style="text-align: left;"><span style="display: inline-block;font-size: 22px;vertical-align: top;">*</span> All values in USD</div> -->
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red
}

::v-deep #transaction-filter-collapse > .multiselect__tag {
  background-color: #41b883
}

.danger-note {
  margin-bottom: 15px;
  padding: 4px 12px;
  background-color: #ffdddd;
  border-left: 6px solid #f44336;
}
::v-deep .download_button button{
  height: 34px;
}
::v-deep .filter_button button{
  height: 100%;
  margin-left:10px;
}
.datepicker-custom-static{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
  display: inline-block;
  cursor: default;
  background: #fff;
}
 .filter-section{
      box-shadow: 0px 3px 10px #0000001a;
      background: #fff;
      position: absolute;
      top: 0px;
      z-index: 9;
      width: 380px;
      right:0px;
  }
  .top-section{
      border-bottom: 1px solid #F0F0F0;
      padding: 15px;
      padding-left: 5px;
      padding-top: 0px;
  }
  .clear_filter{
    color: #BFBFBF;
    display: inline-block;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
  }
  .bottom-section{
      padding: 15px;
      padding-left: 35px;
  }
  .filter_heading{
      color: #BFBFBF;
      padding-bottom: 15px;
      font-size: 13px;
      padding-top:10px ;
  }
  .checkbox-group{
    position: relative;
    display: inline-block;
  }
  .checkbox-group input{
    display: none;
  }
  .checkbox-group  label {
    cursor: pointer;
    font-size: 13px;
  }
  .checkbox-group  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #DAD9D9;
    border-radius: 4px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    bottom:1px;
  }
  .checkbox-group input:checked+label:before {
    background-color: #252B3A;
    border: 1px solid #252B3A;
  }

  .checkbox-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 25px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }
  .filter_channel,.filter_method{
    display: block;
    border-bottom: 1px solid #F0F0F0;
  }
</style>